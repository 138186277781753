/* eslint-disable quote-props */
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';
import en from 'locale/en';
import de from 'locale/de';
import ru from 'locale/ru';
import tr from 'locale/tr';

const resources = {
  en,
  de,
  ru,
  tr
};

if (localStorage.getItem('i18nextLng') === null) {
  localStorage.setItem('i18nextLng', 'de');
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources,
    fallbackLng: 'de',

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false
    },
    react: {
      transSupportBasicHtmlNodes: true, // allow <br/> and simple html elements in translations
      transKeepBasicHtmlNodesFor: ['br']
    }
  });

export default i18n;
